import stylesApartment from "./apartment.module.scss"
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";


function apartment(){
    return(
        <>
        <Header />




    
        

        <Footer />
        </>
    )
}

export default apartment;