import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import stylesActivities from "./activities.module.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import cavernaDaDorinha from "../../Assets/photos/caverna-da-dorinha.jpg";
import balnearioDaCachoeira from "../../Assets/photos/balneario-da-cachoeira.jpg";
import cachoeiraDoGrim from "../../Assets/photos/cachoeira-do-grim.jpg";

function Activities() {
  const [isLoaded, setIsLoaded] = useState({});

  const handleImageLoad = (index) => {
    setIsLoaded((prevState) => ({ ...prevState, [index]: true }));
  };

  return (
    <>
      <Header />

      <figure className={stylesActivities["wrap-figure"]}>
        <div className={stylesActivities["wrap-txt-figure"]}>
          <p>Atividades</p>
        </div>
      </figure>

      <section className={stylesActivities["section-1"]}>
        <div className={stylesActivities["wrap-txt-section-1"]}>
          <p className="font-g">
            Descubra diversas atividades para fazer em Rurópolis
          </p>
          <p className="font-sm">
            Explore uma variedade de atividades emocionantes disponíveis na
            região. Mergulhe em aventuras ao ar livre e passeios culturais .
            Aproveite cada momento e descubra tudo o que o destino tem a
            oferecer para tornar sua viagem inesquecível!
          </p>
        </div>
      </section>

      <section className={`${stylesActivities["section-2"]} container`}>
        <div className={stylesActivities["wrap-content-section-2"]}>
          <div className={stylesActivities["wrap-txt-section-2"]}>
            <p className="font-m txt-end">CAVERNA DA DORINHA</p>
            <p className="font-xs">
              A Caverna da Dorinha, localizada em Rurópolis - PA, é um ponto
              turístico fascinante que atrai visitantes pela sua beleza natural
              e mistério. Rodeada pela exuberante floresta amazônica, a caverna
              é conhecida por suas formações rochosas impressionantes e trilhas
              que proporcionam uma imersão na natureza. Além de ser um local de
              aventuras e descobertas, é também um importante símbolo da
              biodiversidade e da história da região.
            </p>
            <p className="font-s txt-end line-button-right">
              Fale concosco para conhecer
            </p>
          </div>
          <div className={stylesActivities["wrap-img-section-2"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesActivities["img-section-2"]} ${
                  stylesActivities["img-start"]
                } ${isLoaded[1] ? stylesActivities["fade-in"] : ""}`}
                src={cavernaDaDorinha}
                alt=""
                onLoad={() => handleImageLoad(1)}
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className={`${stylesActivities["section-3"]} container`}>
        <div className={stylesActivities["wrap-content-section-3"]}>
          <div className={stylesActivities["wrap-img-section-3"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesActivities["img-section-3"]} ${
                  stylesActivities["img-start"]
                } ${isLoaded[2] ? stylesActivities["fade-in"] : ""}`}
                src={balnearioDaCachoeira}
                alt=""
                onLoad={() => handleImageLoad(2)}
              />
            </LazyLoad>
          </div>
          <div className={stylesActivities["wrap-txt-section-3"]}>
            <p className="font-m txt-end">BALNEÁRIO DA CACHOEIRA</p>
            <p className="font-xs">
              O Balneário da Cachoeira, localizado em Rurópolis - PA, é o
              refúgio perfeito para quem busca relaxar e se conectar com a
              natureza. Com águas cristalinas e quedas d'água revigorantes, o
              local oferece uma experiência tranquila em meio à paisagem
              exuberante. Desfrute de momentos de lazer e paz em um ambiente
              natural único!
            </p>
            <p className="font-s txt-end line-button-left">
              Fale concosco para conhecer
            </p>
          </div>
        </div>
      </section>

      <section className={`${stylesActivities["section-4"]} container`}>
        <div className={stylesActivities["wrap-content-section-4"]}>
          <div className={stylesActivities["wrap-txt-section-4"]}>
            <p className="font-m txt-end">CACHOEIRA DO GRIM</p>
            <p className="font-xs">
              A Cachoeira do Grim, localizada em Rurópolis - PA, é um destino
              imperdível para os amantes da natureza e da aventura. Com suas
              águas cristalinas que descem por belas formações rochosas, o local
              oferece um cenário deslumbrante e ideal para relaxar ou explorar
              trilhas na mata ao redor. Uma verdadeira joia escondida, perfeita
              para quem busca tranquilidade e conexão com a natureza!
            </p>
            <p className="font-s txt-end line-button-right">
              Fale concosco para conhecer
            </p>
          </div>
          <div className={stylesActivities["wrap-img-section-4"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesActivities["img-section-4"]} ${
                  stylesActivities["img-start"]
                } ${isLoaded[3] ? stylesActivities["fade-in"] : ""}`}
                src={cachoeiraDoGrim}
                alt=""
                onLoad={() => handleImageLoad(3)}
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className={stylesActivities["section-5"]}>
        <div className={stylesActivities["wrap-contents-section-5"]}>
          <div className={stylesActivities["wrap-txt-section-5"]}>
            <p className="font-g">MAIS DA CACHOEIRA DO GRIM</p>
            <p className="font-s-bold">
              Descubra a beleza da Cachoeira do Grim! Águas cristalinas, quedas
              majestosas e natureza exuberante em um só lugar. Confira e se
              encante com esse paraíso natural!
            </p>
          </div>
          <div>
            <iframe
              width="875"
              height="492"
              src="https://www.youtube-nocookie.com/embed/KRe7KtmwXnE?si=91LWrv6XU6Y7qbcp&amp;controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className={stylesActivities["wrap-txt-2-section-5"]}>
            <p className="fon-s line-button-right">Fale concosco para conhecer</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Activities;
