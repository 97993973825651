import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import fxexample from "../../Assets/photos/ftexmpl.jpg";
import stylesOffers from "./offers.module.scss";
import photoex3 from "../../Assets/photos/scenario-4.png"

function Offers() {
  const [isLoaded, setIsLoaded] = useState({});

  const handleImageLoad = (index) => {
    setIsLoaded((prevState) => ({ ...prevState, [index]: true }));
  };


  return (
    <>
      <Header />

      <figure className={stylesOffers["wrap-figure"]}>
        <div className={stylesOffers["wrap-txt-figure"]}>
          <p>Ofertas</p>
        </div>
      </figure>

      <section className={stylesOffers["section-1"]}>
        <div className={stylesOffers["wrap-txt-section-1"]}>
          <p className="font-g">Ofertas</p>
          <p className="font-sm">
          Faça do Copacabana Palace seu lar longe de casa enquanto desfruta dos encantos do Rio.
          </p>
          <p className="font-s line-button-right">
            Entre em contato e conheça nossas ofertas
          </p>
        </div>
      </section>

      <section className={stylesOffers["section-5"]}>
        <div className={stylesOffers["wrap-img-section-5"]}>
        <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesOffers["img-section-5"]} ${
                  stylesOffers["img-start"]
                } ${isLoaded[1] ? stylesOffers["fade-in"] : ""}`}
                src={fxexample}
                alt=""
                onLoad={() => handleImageLoad(1)}
              />
            </LazyLoad>
        </div>
        <div className={stylesOffers["wrap-all-txt"]}>
          <div className={`${stylesOffers["txt-1-section-5"]} font-s`}>
            <p>comemore</p>
          </div>
          <div className={stylesOffers["wrap-txt-section-5"]}>
            <div className="font-m">
              <p>venha comemorar</p>
            </div>

            <div className="font-xs">
              <p>
                Seja para um aniversário, bodas ou lua de mel, este pacote
                comemorativo inclui adoráveis mimos que tornarão sua estadia
                verdadeiramente inesquecível.
              </p>
            </div>

            <div className={stylesOffers["list-section-5"]}>
              <ul>
                <li>
                  <p className="txt-forma-before font-xs">Acomodação de luxo</p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Gift de boas-vindas, incluindo espumante, flores e trufas de
                    chocolate
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Café da manhã brasileiro diário para duas pessoas no
                    Restaurante Pérgula
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Check-out tardio até as 16h
                  </p>
                </li>
              </ul>
            </div>
            <div className={`${stylesOffers["txt-4-section-5"]} font-s`}>
              <p>verificar disponibilidade</p>
            </div>
          </div>
        </div>
      </section>

      <section className={stylesOffers["section-5"]}>
        <div className={stylesOffers["wrap-img-section-5"]}>
        <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesOffers["img-section-5"]} ${
                  stylesOffers["img-start"]
                } ${isLoaded[2] ? stylesOffers["fade-in"] : ""}`}
                src={fxexample}
                alt=""
                onLoad={() => handleImageLoad(2)}
              />
            </LazyLoad>
        </div>
        <div className={stylesOffers["wrap-all-txt"]}>
          <div className={`${stylesOffers["txt-1-section-5"]} font-s`}>
            <p>comemore</p>
          </div>
          <div className={stylesOffers["wrap-txt-section-5"]}>
            <div className="font-m">
              <p>venha comemorar</p>
            </div>

            <div className="font-xs">
              <p>
                Seja para um aniversário, bodas ou lua de mel, este pacote
                comemorativo inclui adoráveis mimos que tornarão sua estadia
                verdadeiramente inesquecível.
              </p>
            </div>

            <div className={stylesOffers["list-section-5"]}>
              <ul>
                <li>
                  <p className="txt-forma-before font-xs">Acomodação de luxo</p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Gift de boas-vindas, incluindo espumante, flores e trufas de
                    chocolate
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Café da manhã brasileiro diário para duas pessoas no
                    Restaurante Pérgula
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Check-out tardio até as 16h
                  </p>
                </li>
              </ul>
            </div>
            <div className={`${stylesOffers["txt-4-section-5"]} font-s`}>
              <p>verificar disponibilidade</p>
            </div>
          </div>
        </div>
      </section>

      <section className={stylesOffers["section-5"]}>
        <div className={stylesOffers["wrap-img-section-5"]}>
        <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesOffers["img-section-5"]} ${
                  stylesOffers["img-start"]
                } ${isLoaded[3] ? stylesOffers["fade-in"] : ""}`}
                src={fxexample}
                alt=""
                onLoad={() => handleImageLoad(3)}
              />
            </LazyLoad>
        </div>
        <div className={stylesOffers["wrap-all-txt"]}>
          <div className={`${stylesOffers["txt-1-section-5"]} font-s`}>
            <p>comemore</p>
          </div>
          <div className={stylesOffers["wrap-txt-section-5"]}>
            <div className="font-m">
              <p>venha comemorar</p>
            </div>

            <div className="font-xs">
              <p>
                Seja para um aniversário, bodas ou lua de mel, este pacote
                comemorativo inclui adoráveis mimos que tornarão sua estadia
                verdadeiramente inesquecível.
              </p>
            </div>

            <div className={stylesOffers["list-section-5"]}>
              <ul>
                <li>
                  <p className="txt-forma-before font-xs">Acomodação de luxo</p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Gift de boas-vindas, incluindo espumante, flores e trufas de
                    chocolate
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Café da manhã brasileiro diário para duas pessoas no
                    Restaurante Pérgula
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Check-out tardio até as 16h
                  </p>
                </li>
              </ul>
            </div>
            <div className={`${stylesOffers["txt-4-section-5"]} font-s`}>
              <p>verificar disponibilidade</p>
            </div>
          </div>
        </div>
      </section>

      <section className={stylesOffers["section-5"]}>
        <div className={stylesOffers["wrap-img-section-5"]}>
        <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesOffers["img-section-5"]} ${
                  stylesOffers["img-start"]
                } ${isLoaded[4] ? stylesOffers["fade-in"] : ""}`}
                src={fxexample}
                alt=""
                onLoad={() => handleImageLoad(4)}
              />
            </LazyLoad>
        </div>
        <div className={stylesOffers["wrap-all-txt"]}>
          <div className={`${stylesOffers["txt-1-section-5"]} font-s`}>
            <p>comemore</p>
          </div>
          <div className={stylesOffers["wrap-txt-section-5"]}>
            <div className="font-m">
              <p>venha comemorar</p>
            </div>

            <div className="font-xs">
              <p>
                Seja para um aniversário, bodas ou lua de mel, este pacote
                comemorativo inclui adoráveis mimos que tornarão sua estadia
                verdadeiramente inesquecível.
              </p>
            </div>

            <div className={stylesOffers["list-section-5"]}>
              <ul>
                <li>
                  <p className="txt-forma-before font-xs">Acomodação de luxo</p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Gift de boas-vindas, incluindo espumante, flores e trufas de
                    chocolate
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Café da manhã brasileiro diário para duas pessoas no
                    Restaurante Pérgula
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Check-out tardio até as 16h
                  </p>
                </li>
              </ul>
            </div>
            <div className={`${stylesOffers["txt-4-section-5"]} font-s`}>
              <p>verificar disponibilidade</p>
            </div>
          </div>
        </div>
      </section>

      <section className={stylesOffers["section-6"]}>
        <div className={stylesOffers["wrap-contents-section-6"]}>
        <div className={stylesOffers["wrap-txt-section-6"]}>
          <p className="font-g">Pacotes & experiências</p>
          <p className="font-s-bold">
            Festeje no hotel mais estrelado do Rio antes de explorar as
            florestas tropicais de Iguaçu. A América do Sul é repleta de
            possibilidades—para aonde você vai?
          </p>
        </div>
        <div className="img-section-6">
        <LazyLoad height={200} offset={100} once>
              <img
                className={`${
                  stylesOffers["img-start"]
                } ${isLoaded[5] ? stylesOffers["fade-in"] : ""}`}
                src={photoex3}
                alt=""
                onLoad={() => handleImageLoad(5)}
              />
            </LazyLoad>
        </div>
        <div className={stylesOffers["wrap-txt-2-section-6"]}>
          <p className="font-s">5 noites: Rio de Janeiro e Iguaçu</p>
          <p className="font-m">Grand Tour pelo Brasil</p>
          <p className="fon-s line-button-right">Ver itinerário</p>
        </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Offers;
