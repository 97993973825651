import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import stylesEvents from "./events.module.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import eventos1 from "../../Assets/photos/foto-1-eventos.jpg";
import eventos2 from "../../Assets/photos/foto-2-eventos.jpg";
import eventos3 from "../../Assets/photos/foto-3-eventos.jpg";

function Events() {
  const [isLoaded, setIsLoaded] = useState({});

  const handleImageLoad = (index) => {
    setIsLoaded((prevState) => ({ ...prevState, [index]: true }));
  };

  return (
    <>
      <Header />

      <figure className={stylesEvents["wrap-figure"]}>
        <div className={stylesEvents["wrap-txt-figure"]}>
          <p>Eventos</p>
        </div>
      </figure>

      <section className={stylesEvents["section-1"]}>
        <div className={stylesEvents["wrap-txt-section-1"]}>
          <p className="font-g">
            Sala de Eventos e Reuniões no Planalto Palace Hotel
          </p>
          <p className="font-sm">
            No Planalto Palace Hotel, oferecemos o ambiente ideal para realizar
            suas reuniões de negócios, conferências e eventos. Nossa sala de
            eventos e reuniões foi projetada para oferecer conforto, praticidade
            e a mais moderna tecnologia, garantindo que cada encontro seja um
            sucesso.
          </p>
          <p className="fonnt-s line-button-right">
            Entre em contato com os nossos especialistas
          </p>
        </div>
      </section>

      <section className={`${stylesEvents["section-3"]} container`}>
        <div className={stylesEvents["wrap-content-section-3"]}>
          <div className={stylesEvents["wrap-img-section-3"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesEvents["img-section-3"]} ${
                  stylesEvents["img-start"]
                } ${isLoaded[1] ? stylesEvents["fade-in"] : ""}`}
                src={eventos1}
                alt=""
                onLoad={() => handleImageLoad(1)}
              />
            </LazyLoad>
          </div>
          <div className={stylesEvents["wrap-txt-content-section-3"]}>
            <p className="font-m txt-end">
              Deixe sua reunião, palestra ou evento ainda mais profissional.
            </p>
            <div className={stylesEvents["wrap-txt-section-3"]}>
              <p className="txt-forma-before font-xs">Ar - condicionado</p>
              <p className="txt-forma-before font-xs">Televisão</p>
              <p className="txt-forma-before font-xs">Ótima localização</p>
              <p className="txt-forma-before font-xs">
                Internet de alta velocidade
              </p>
            </div>
            <p className="font-s txt-end line-button-left">Saiba mais</p>
          </div>
        </div>
      </section>

      <section className={`${stylesEvents["section-4"]} container`}>
        <div className={stylesEvents["wrap-content-section-4"]}>
          <div className={stylesEvents["wrap-txt-section-4"]}>
            <p className="font-m txt-end">
              O Local Ideal para Palestras Inspiradoras
            </p>
            <p className="font-xs">
              No Planalto Palace Hotel, oferecemos um ambiente profissional e
              moderno para a realização de palestras que deixam uma marca. Com
              espaços amplos e tecnologia de ponta, proporcionamos tudo o que
              você precisa para um evento de sucesso.
            </p>
            <p className="font-xs">
              Realize Suas Palestras com Excelência Planeje sua próxima palestra
              conosco e proporcione uma experiência envolvente e inesquecível
              para seus participantes. Entre em contato para saber mais sobre
              nossas opções de espaços e serviços.
            </p>
            <p className="font-s txt-end line-button-right">Fale conosco</p>
          </div>
          <div className={stylesEvents["wrap-img-section-4"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesEvents["img-section-4"]} ${
                  stylesEvents["img-start"]
                } ${isLoaded[2] ? stylesEvents["fade-in"] : ""}`}
                src={eventos2}
                alt=""
                onLoad={() => handleImageLoad(2)}
              />
            </LazyLoad>
          </div>
        </div>
      </section>

    {/*   <section className={`${stylesEvents["section-3"]} container`}>
        <div className={stylesEvents["wrap-content-section-3"]}>
          <div className={stylesEvents["wrap-img-section-3"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesEvents["img-section-3"]} ${
                  stylesEvents["img-start"]
                } ${isLoaded[3] ? stylesEvents["fade-in"] : ""}`}
                src={photoex2}
                alt=""
                onLoad={() => handleImageLoad(3)}
              />
            </LazyLoad>
          </div>
          <div className={stylesEvents["wrap-txt-section-3"]}>
            <p className="font-m txt-end">Enfim, sós</p>
            <p className="font-xs">
              Areia dourada e mar azul. Acordar com a vista deslumbrante da
              Praia de Copacabana ao lado de quem você ama é uma cena que vai
              repassar muitas vezes na sua lembrança. Aproveite dias incríveis
              para desbravar o que o Rio tem de melhor e descanse no nosso SPA
              ou na famosa piscina do Copa rodeada por três restaurantes e um
              Pool Bar. Gastronomia, com certeza, será um ponto alto da sua
              viagem, pois somos o único hotel da América do Sul com dois
              restaurantes com Estrela Michelin.
            </p>
            <p className="font-s txt-end line-button-left">Fale Conosco</p>
          </div>
        </div>
      </section>

      <section className={`${stylesEvents["section-4"]} container`}>
        <div className={stylesEvents["wrap-content-section-4"]}>
          <div className={stylesEvents["wrap-txt-section-4"]}>
            <p className="font-m txt-end">Perfeito em cada detalhe</p>
            <p className="font-xs">
              O Rio já é um lugar inspirador por natureza. Agora imagine isso
              somado à atmosfera majestosa do Copacabana Palace. Todas as metas
              serão batidas. Se você está à procura de um destino para
              conferências ou reuniões de incentivo, temos tudo para oferecer ao
              seu negócio nos nossos 13 espaços de eventos, dos mais variados
              formatos e tamanhos. Combinando nossa clássica grandiosidade com
              serviço de excelência, nós cuidamos de todos os detalhes para
              tornar seu encontro um sucesso.
            </p>
            <p className="font-s txt-end line-button-right">Fale conosco</p>
          </div>
          <div className={stylesEvents["wrap-img-section-4"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesEvents["img-section-4"]} ${
                  stylesEvents["img-start"]
                } ${isLoaded[4] ? stylesEvents["fade-in"] : ""}`}
                src={photoex2}
                alt=""
                onLoad={() => handleImageLoad(4)}
              />
            </LazyLoad>
          </div>
        </div>
      </section> */}

      <section className={stylesEvents["section-5"]}>
        <div className={stylesEvents["wrap-contents-section-5"]}>
          <div className={stylesEvents["wrap-txt-section-5"]}>
            <p className="font-g">Eventos e Reuniões</p>
            <p className="font-s-bold">
              Defina o cenário para uma reunião ou evento inesquecível
            </p>
          </div>
          <div className={stylesEvents["wrap-img-section-5"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesEvents["img-start"]} ${
                  isLoaded[5] ? stylesEvents["fade-in"] : ""
                }`}
                src={eventos3}
                alt=""
                onLoad={() => handleImageLoad(5)}
              />
            </LazyLoad>
          </div>
          <div className={stylesEvents["wrap-txt-2-section-5"]}>
            <p className="font-s">O lugar perfeito para o seu evento ou reunião</p>
            <p className="font-sm">
              De uma reunião mais íntima até eventos, temos o espaço
              ideal para a sua reunião ou comemoração.
            </p>
            <p className="fon-s line-button-right">Mais detalhes</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Events;
