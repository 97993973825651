import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import stylesHeader from './header.module.scss';
import stylesSidebar from './sidebar.module.scss';
import icons from '../../styles/icons.js';
import useSidebarStore from '../../zustand/store/useSidebarStore.jsx';

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({ name: 'Português', icon: icons.bandeiraBrasil });
  const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);
  const setIsSidebarOpen = useSidebarStore((state) => state.setIsSidebarOpen);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown((prev) => !prev);
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  const closeSidebar = () => setIsSidebarOpen(false);
  
  const handleScroll = () => {
    setIsNavbarFixed(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('click', handleClickOutsideDropdown, true);
    } else {
      document.removeEventListener('click', handleClickOutsideDropdown, true);
    }

    return () => document.removeEventListener('click', handleClickOutsideDropdown, true);
  }, [showDropdown]);

  useEffect(() => {
    const handleClickOutsideSidebar = (event) => {
      const sidebarElement = document.querySelector(`.${stylesSidebar.sidebar}`);
      const wrapMenuElement = document.querySelector(`.${stylesHeader["wrap-menu"]}`);
      
      if (
        sidebarElement && 
        !sidebarElement.contains(event.target) && 
        !wrapMenuElement.contains(event.target)
      ) {
        closeSidebar();
      }
    };
  
    if (isSidebarOpen) {
      document.addEventListener('click', handleClickOutsideSidebar, true);
    } else {
      document.removeEventListener('click', handleClickOutsideSidebar, true);
    }
  
    return () => document.removeEventListener('click', handleClickOutsideSidebar, true);
  }, [isSidebarOpen]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setShowDropdown(false);
  };

  return (
    <>
      <header>
        <div className={`${stylesHeader["header"]} font-sm`}>
          <div className={stylesHeader["wrap-menu"]} onClick={toggleSidebar}>
            <img className="icons-sm" src={icons.menuicon} alt="menu" />
            <p>Menu</p>
          </div>

          <div className={stylesHeader["wrap-logo"]}>
            <img className={stylesHeader.logo} src={icons.logoVergeOriginal} alt="logo" />
          </div>

          <div className={stylesHeader["wrap-options"]}>
            <div className={`${stylesHeader.option} ${stylesHeader.dropdown}`} ref={dropdownRef}>
              <div onClick={toggleDropdown} className={stylesHeader["dropdown-toggle"]}>
                <img className="icons-sm" src={selectedLanguage.icon} alt="idioma" />
                <p>{selectedLanguage.name}</p>
              </div>

              {showDropdown && (
                <div className={stylesHeader["dropdown-menu"]}>
                  <div className={stylesHeader["wrap-images-language"]} onClick={() => handleLanguageChange({ name: 'Português', icon: icons.bandeiraBrasil })}>
                    <img className="icons-sm" src={icons.bandeiraBrasil} alt="Bandeira do Brasil" />
                    <p>Português</p>
                  </div>
                  <div className={stylesHeader["wrap-images-language"]} onClick={() => handleLanguageChange({ name: 'English', icon: icons.bandeiraIngles })}>
                    <img className="icons-sm" src={icons.bandeiraIngles} alt="Flag English" />
                    <p>English</p>
                  </div>
                  <div className={stylesHeader["wrap-images-language"]} onClick={() => handleLanguageChange({ name: 'Español', icon: icons.bandeiraEspanha })}>
                    <img className="icons-sm" src={icons.bandeiraEspanha} alt="Flag Spanish" />
                    <p>Español</p>
                  </div>
                  <div className={stylesHeader["wrap-images-language"]} onClick={() => handleLanguageChange({ name: 'Français', icon: icons.bandeiraFranca })}>
                    <img className="icons-sm" src={icons.bandeiraFranca} alt="Flag French" />
                    <p>Français</p>
                  </div>
                  <div className={stylesHeader["wrap-images-language"]} onClick={() => handleLanguageChange({ name: 'Italiano', icon: icons.bandeiraItalia })}>
                    <img className="icons-sm" src={icons.bandeiraItalia} alt="Flag Italian" />
                    <p>Italiano</p>
                  </div>
                </div>
              )}
            </div>

            <div className={stylesHeader.option}>
              <img className="icons-sm" src={icons.user} alt="usuário" />
              <p>Conta</p>
            </div>

            <div className={stylesHeader["wrap-reserva"]}>
              <img className="icons-sm" src={icons.reserva} alt="reserva" />
              <p>Reservar</p>
            </div>
          </div>
        </div>

        <nav className={`${isNavbarFixed ? 'fixed-navbar' : ''}`}>
          <div className={`${stylesSidebar.sidebar} ${isSidebarOpen ? stylesSidebar.show : ''}`}>
            <div className={stylesSidebar["wrap-section-1-sidebar"]}>
              <div className={stylesSidebar["wrap-logo-and-close"]}>
                <img className={stylesSidebar["logo-sidebar"]} src={icons.logoVerde} alt="" />
                <img
                  onClick={closeSidebar}
                  id={stylesSidebar["close-btn"]}
                  className="icons-sm"
                  src={icons.close}
                  alt="close"
                />
              </div>
            </div>
            <ul className={`${stylesSidebar["sidebar-options"]} font-sm`}>
            <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.hotelicon} alt="hotel" />
                  <Link to="/Ohotel" className="link-no-style">
                    O hotel
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.galery} alt="" />
                  <Link to="/Galeria" className="link-no-style">
                    Galeria
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.bed} alt="" />
                  <Link to="/Acomodações" className="link-no-style">
                    Acomodações
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.restaurant} alt="" />
                  <Link to="/Restaurante" className="link-no-style">
                    Restaurante
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.activities} alt="" />
                  <Link to="/Atividades" className="link-no-style">
                    Atividades
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.calendar} alt="" />
                  <Link to="/Eventos" className="link-no-style">
                    Eventos
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.offer} alt="" />
                  <Link to="/Ofertas" className="link-no-style">
                    Ofertas
                  </Link>
                </div>
              </li>
            </ul>

            <ul className={`${stylesSidebar["sidebar-options-2"]} font-sm`}>
            <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.user} alt="" />
                  <Link to="/Conta" className="link-no-style">
                    Conta
                  </Link>
                </div>
              </li>
              <li>
                <div className={stylesSidebar["wrap-options-sidebar"]}>
                  <img className="icons-m" src={icons.whatsapp} alt="" />
                  <Link to="/FaleConosco" className="link-no-style">
                    Fale Conosco
                  </Link>
                </div>
              </li>
            </ul>

            <ul className={`${stylesSidebar["sidebar-options-3"]} font-sm`}>
            <li>
                <div className={stylesSidebar["wrap-options-redes-sidebar"]}>
                  <a href="https://www.instagram.com/planaltopalacehotel/" target="blank" rel="noopener noreferrer">
                  <img className="icons-m" src={icons.instagram} alt="" />
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=559391288224" target="blank" rel="noopener noreferrer">
                  <img className="icons-m" src={icons.whatsapp} alt="" />
                  </a>
                  <a href="https://www.facebook.com/HOTELPLANALTOPALACE" target="blank" rel="noopener noreferrer">
                  <img className="icons-m" src={icons.facebook} alt="" />
                  </a>
                </div>
              </li>
            </ul>
          </div>

          <div className={stylesHeader["wrap-navbar"]}>
          <ul className={`${stylesHeader["navbar-options"]} font-sm`}>
              <li className="txt-forma">
                <Link to="/Ohotel" className="link-no-style">
                  O hotel
                </Link>
              </li>
              <li className="txt-forma">
                <Link to="/Galeria" className="link-no-style">
                  Galeria
                </Link>
              </li>
              <li className="txt-forma">
                <Link to="/Acomodações" className="link-no-style">
                  Acomodações
                </Link>
              </li>
              <li className="txt-forma">
                <Link to="/Restaurante" className="link-no-style">
                  Restaurante
                </Link>
              </li>
              <li className="txt-forma">
                <Link to="/Atividades" className="link-no-style">
                  Atividades
                </Link>
              </li>
              <li className="txt-forma">
                <Link to="/Eventos" className="link-no-style">
                  Eventos
                </Link>
              </li>
              <li >
                <Link to="/Ofertas" className="link-no-style">
                  Ofertas
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
