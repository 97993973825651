import photo1 from "./photo-1.jpg"
import photo2 from "./photo-2.jpg"
import photo3 from "./photo-3.jpg"
import photo4 from "./photo-4.jpg"
import photo5 from "./photo-5.jpg"
import photo6 from "./photo-6.jpg"
import photo7 from "./photo-7.jpg"
import photo8 from "./photo-8.jpg"
import photo9 from "./photo-9.jpg"
import photo10 from "./photo-10.jpg"
import photo11 from "./photo-11.jpg"
import photo12 from "./photo-12.jpg"
import photo13 from "./photo-13.jpg"
import photo14 from "./photo-14.jpg"
import photo15 from "./photo-15.jpg"
import photo16 from "./photo-16.jpg"
import photo17 from "./photo-17.jpg"
import photo18 from "./photo-18.jpg"
import photo19 from "./photo-19.jpg"
import photo20 from "./photo-20.jpg"
import photo21 from "./photo-21.jpg"
import photo22 from "./photo-22.jpg"
import photo23 from "./photo-23.png"
import photo24 from "./photo-24.png"
import photo25 from "./photo-25.jpg"
import photo26 from "./photo-26.jpg"
import photo27 from "./photo-27.jpg"
import photo28 from "./photo-28.png"
import photo29 from "./photo-29.png"
import photo30 from "./photo-30.png"
import photo31 from "./photo-31.png"
import photo32 from "./photo-32.JPG"
import photo33 from "./photo-33.JPG"
import photo34 from "./photo-34.jpg"
import photo35 from "./photo-35.jpg"


export default{
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
    photo17,
    photo18,
    photo19,
    photo20,
    photo21,
    photo22,
    photo23,
    photo24,
    photo25,
    photo26,
    photo27,
    photo28,
    photo29,
    photo30,
    photo31,
    photo32,
    photo33,
    photo34,
    photo35,

}