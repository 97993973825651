import logo from "../Assets/icons/logo hotel.png";
import menuicon from "../Assets/icons/menu.png";
import setabaixo from "../Assets/icons/setabaixo.png";
import user from "../Assets//icons/user.png";
import reserva from "../Assets//icons/reserva.png";
import facebook from "../Assets//icons/facebook.png";
import instagram from "../Assets//icons/instagram.png";
import logoVerde from "../Assets//icons/logoVerde.png";
import logoVergeOriginal from "../Assets//icons/logo hotel verde.png";
import close from "../Assets//icons/close.png";
import hotelicon from "../Assets/icons/hotel_icon.png";
import galery from "../Assets/icons/galery.png";
import bed from "../Assets/icons/bed.png";
import restaurant from "../Assets/icons/restaurant.png";
import activities from "../Assets/icons/activities.png"
import calendar from "../Assets/icons/calendar.png"
import offer from "../Assets/icons/offer.png"
import whatsapp from "../Assets/icons/whatsapp.png"
import star from "../Assets/icons/star.png"
import question from "../Assets/icons/question.png"
import phone from "../Assets/icons/phone.png"
import bandeiraBrasil from "../Assets/icons/bandeira-brasil.png"
import bandeiraIngles from "../Assets/icons/bandeira-ingles.png"
import bandeiraEspanha from "../Assets/icons/bandeira-espanha.png"
import bandeiraFranca from "../Assets/icons/bandeira-franca.png"
import bandeiraItalia from "../Assets/icons/bandeira-italia.png"

export default {
  logo,
  menuicon,
  setabaixo,
  user,
  reserva,
  facebook,
  logoVergeOriginal,
  instagram,
  logoVerde,
  close,
  hotelicon,
  galery,
  bed,
  restaurant,
  activities,
  calendar,
  offer,
  whatsapp,
  star,
  question,
  phone,
  bandeiraBrasil,
  bandeiraIngles,
  bandeiraEspanha,
  bandeiraFranca,
  bandeiraItalia
};
