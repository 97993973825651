import React, { useState } from "react";
import stylesHome from "./home.module.scss";
import LazyLoad from "react-lazyload";
import "../../styles/global.css";
import Footer from "../../components/footer/footer.jsx";
import presidencial from "../../Assets/photos/photo-suite-presidencial.jpg";
import family from "../../Assets/photos/photo-family.jpg";
import duplo from "../../Assets/photos/photo-duplo.png";
import triplo from "../../Assets/photos/photo-triplo.jpg";
import quarto from "../../Assets/photos/foto-apartamentos.jpg";
import medida from "../../Assets/photos/photo-medida.png";
import phone from "../../Assets/photos/photo-phone.png";
import hospedese from "../../Assets/photos/photo-hospedese.png";
import fxexample from "../../Assets/photos/ftexmpl.jpg";
import chavenova from "../../Assets/images/Chave nova.png";
import Header from "../../components/header/header.jsx";

function Home() {
  const [isLoaded, setIsLoaded] = useState({});

  const handleImageLoad = (index) => {
    setIsLoaded((prevState) => ({ ...prevState, [index]: true }));
  };

  return (
    <>
      <Header />

      <figure className={stylesHome["wrap-figure"]}>
        <div className={stylesHome["wrap-txt-figure"]}>
          <p>acomodações</p>
          <a>reserve agora</a>
        </div>
      </figure>

      <section className={stylesHome["section-1"]}>
        <div className={stylesHome["wrap-section-1"]}>
          <LazyLoad height={200} offset={100} once>
            <img
              className={`${stylesHome["section-1-img"]} ${["img-start"]} ${
                isLoaded[1] ? stylesHome["fade-in"] : ""
              }`}
              src={chavenova}
              alt="chaves"
              onLoad={() => handleImageLoad(1)}
            />
          </LazyLoad>
          <p id={stylesHome["section-1-txt-sm"]}>
            Sinta- <br /> se em <br /> casa
          </p>
        </div>
        <div className={stylesHome["txt-1-section-1"]}>
          <p id={stylesHome["txt-2-section-1"]}>
            No <strong>Planalto Palace Hotel</strong>, cada detalhe é pensado
            para garantir uma estadia memorável. Nossas acomodações combinam
            luxo e modernidade, proporcionando o conforto ideal para relaxar,
            seja em uma viagem a negócios ou lazer. Com uma decoração elegante e
            ambientes acolhedores, cada quarto foi projetado para oferecer o
            máximo em conforto e praticidade. Aproveite nossas comodidades
            exclusivas e relaxe em um ambiente que equilibra sofisticação e
            aconchego.
          </p>
          <p id={stylesHome["txt-2-section-1"]}>
            No <strong>Planalto Palace Hotel</strong> , você não apenas se
            hospeda, você vive uma experiência única.
          </p>
        </div>
      </section>

      <section className={stylesHome["section-2"]}>
        <div className={stylesHome["wrap-section-2"]}>
          <p className="font-g">apartamentos e suítes</p>
          <p id={stylesHome["section-2-txt-2"]}>
            Decidindo onde ficar em Rurópolis? Nossos apartamentos e suítes são
            os mais exclusivos da cidade e região.
          </p>
          <LazyLoad height={200} offset={100} once>
            <img
              className={`${stylesHome["wrap-photo-section-2"]} ${
                stylesHome["img-start"]
              }  ${isLoaded[2] ? stylesHome["fade-in"] : ""}`}
              src={quarto}
              alt="foto quarto"
              onLoad={() => handleImageLoad(2)}
            />
          </LazyLoad>
          <div
            className={stylesHome["border-bottom-wrap-photo-section-2"]}
          ></div>
          <p className="font-m">apartamentos</p>
          <div className={stylesHome["wrap-desc"]}>
            <p className="font-xs txt-bola"> Cama king size</p>
            <p className="font-xs txt-bola"> Banheiro espaçoso</p>
            <p className="font-xs"> Tamanhos de até 60 m²</p>
          </div>
          <p className="btn-border">RESERVAR</p>
        </div>
      </section>

      <section className={stylesHome["section-3"]}>
        <div className={stylesHome["wrap-contents-section-3"]}>
          <div className={stylesHome["wrap-content-section-3"]}>
            <div className={stylesHome["wrap-img-section-3"]}>
              <LazyLoad height={200} offset={100} once>
                <img
                  className={`${stylesHome["img-content-section-3"]} ${
                    stylesHome["img-start"]
                  } ${isLoaded[3] ? stylesHome["fade-in"] : ""}`}
                  src={presidencial}
                  alt="suite"
                  onLoad={() => handleImageLoad(3)}
                />
              </LazyLoad>
              <p className="font-m">Suíte Presidencial</p>
            </div>
            <div className={stylesHome["wrap-desc"]}>
              <p className="font-xs txt-bola">cama super king 2x2</p>
              <p className="font-xs txt-bola">Wifi</p>
              <p className="font-xs txt-bola">TV</p>
              <p className="font-xs txt-bola">banheira</p>
              <p className="font-xs txt-bola">quarto amplo</p>
              <p className="font-xs txt-bola">estacionamento privativo</p>
              <p className="font-xs">Tamanho: a partir de 60 m²</p>
            </div>
            <p className="btn-border">RESERVAR</p>
          </div>

          <div className={stylesHome["wrap-content-section-3"]}>
            <div className={stylesHome["wrap-img-section-3"]}>
              <LazyLoad height={200} offset={100} once>
                <img
                  className={`${stylesHome["img-content-section-3"]} ${
                    stylesHome["img-start"]
                  } ${isLoaded[3] ? stylesHome["fade-in"] : ""}`}
                  src={family}
                  alt="suite"
                  onLoad={() => handleImageLoad(3)}
                />
              </LazyLoad>
              <p className="font-m">Apartamento família</p>
            </div>
            <div className={stylesHome["wrap-desc"]}>
              <p className="font-xs txt-bola">1 cama casal</p>
              <p className="font-xs txt-bola">2 camas solteiro</p>
              <p className="font-xs txt-bola">apartamento amplo</p>
              <p className="font-xs txt-bola">sofá</p>
              <p className="font-xs txt-bola">Wifi</p>
              <p className="font-xs txt-bola">TV</p>
              <p className="font-xs">Tamanho: a partir de 30 m²</p>
            </div>
            <p className="btn-border">RESERVAR</p>
          </div>
        </div>

        <div className={stylesHome["wrap-contents-section-3"]}>
          <div className={stylesHome["wrap-content-section-3"]}>
            <div className={stylesHome["wrap-img-section-3"]}>
              <LazyLoad height={200} offset={100} once>
                <img
                  className={`${stylesHome["img-content-section-3"]} ${
                    stylesHome["img-start"]
                  } ${isLoaded[3] ? stylesHome["fade-in"] : ""}`}
                  src={triplo}
                  alt="suite"
                  onLoad={() => handleImageLoad(3)}
                />
              </LazyLoad>
              <p className="font-m">Apartamento triplo</p>
            </div>
            <div className={stylesHome["wrap-desc"]}>
              <p className="font-xs txt-bola">
                1 cama casal e 1 cama solteiro ou 3 camas solteiro
              </p>
              <p className="font-xs txt-bola">apartamento amplo</p>
              <p className="font-xs txt-bola">Wifi</p>
              <p className="font-xs txt-bola">TV</p>
              <p className="font-xs">Tamanho: a partir de 20 m²</p>
            </div>
            <p className="btn-border">RESERVAR</p>
          </div>

          <div className={stylesHome["wrap-content-section-3"]}>
            <div className={stylesHome["wrap-img-section-3"]}>
              <LazyLoad height={200} offset={100} once>
                <img
                  className={`${stylesHome["img-content-section-3"]} ${
                    stylesHome["img-start"]
                  } ${isLoaded[3] ? stylesHome["fade-in"] : ""}`}
                  src={duplo}
                  alt="suite"
                  onLoad={() => handleImageLoad(3)}
                />
              </LazyLoad>
              <p className="font-m">Apartamento duplo</p>
            </div>
            <div className={stylesHome["wrap-desc"]}>
              <p className="font-xs txt-bola">2 camas solteiro</p>
              <p className="font-xs txt-bola">apartamento amplo</p>
              <p className="font-xs txt-bola">Wifi</p>
              <p className="font-xs txt-bola">TV</p>
              <p className="font-xs">Tamanho: a partir de 15 m²</p>
            </div>
            <p className="btn-border">RESERVAR</p>
          </div>
        </div> 

        <div className={stylesHome["wrap-contents-section-3"]}>
          <div className={stylesHome["wrap-content-section-3"]}>
            <div className={stylesHome["wrap-img-section-3"]}>
              <LazyLoad height={200} offset={100} once>
                <img
                  className={`${stylesHome["img-content-section-3"]} ${
                    stylesHome["img-start"]
                  } ${isLoaded[3] ? stylesHome["fade-in"] : ""}`}
                  src={triplo}
                  alt="suite"
                  onLoad={() => handleImageLoad(3)}
                />
              </LazyLoad>
              <p className="font-m">Apartamento individual</p>
            </div>
            <div className={stylesHome["wrap-desc"]}>
              <p className="font-xs txt-bola"> 1 cama casal</p>
              <p className="font-xs txt-bola">apartamento amplo</p>
              <p className="font-xs txt-bola">Wifi</p>
              <p className="font-xs txt-bola">TV</p>
              <p className="font-xs">Tamanho: a partir de 20 m²</p>
            </div>
            <p className="btn-border">RESERVAR</p>
          </div>

          <div className={stylesHome["wrap-content-section-3"]}>
            <div className={stylesHome["wrap-img-section-3"]}>
              <LazyLoad height={200} offset={100} once>
                <img
                  className={`${stylesHome["img-content-section-3"]} ${
                    stylesHome["img-start"]
                  } ${isLoaded[3] ? stylesHome["fade-in"] : ""}`}
                  src={duplo}
                  alt="suite"
                  onLoad={() => handleImageLoad(3)}
                />
              </LazyLoad>
              <p className="font-m">Apartamento Casal</p>
            </div>
            <div className={stylesHome["wrap-desc"]}>
              <p className="font-xs txt-bola"> 1 cama casal</p>
              <p className="font-xs txt-bola">apartamento amplo</p>
              <p className="font-xs txt-bola">Wifi</p>
              <p className="font-xs txt-bola">TV</p>
              <p className="font-xs">Tamanho: a partir de 30 m²</p>
            </div>
            <p className="btn-border">RESERVAR</p>
          </div>
        </div> 
        
      </section>

      

      <section className={stylesHome["section-4"]}>
        <div className={stylesHome["wrap-content-section-4"]}>
          <div className={stylesHome["wrap-txt-section-4"]}>
            <p id={stylesHome["txt-1-section-4"]} className="font-m">
              Descanso e Conforto Sob Medida para Você
            </p>
            <p id={stylesHome["txt-2-section-4"]} className="font-xs">
              Descanse em acomodações elegantes e confortáveis, perfeitas para
              relaxar após um dia cheio. Nossos quartos e suítes oferecem o
              equilíbrio ideal entre conforto e estilo, com amenidades como
              wi-fi gratuito e camas espaçosas. Escolha a categoria que mais
              combina com você e aproveite uma estadia inesquecível.
            </p>
            <p className="font-s line-button-left">
              Contacte-nos para reservar
            </p>
          </div>
          <div className={stylesHome["img-section-4"]}>
            <LazyLoad height={200} offset={100} once>
              <img
                className={`${stylesHome["img-content-phone-4"]} ${
                  stylesHome["img-start"]
                } ${isLoaded[4] ? stylesHome["fade-in"] : ""}`}
                src={phone}
                alt="suite"
                onLoad={() => handleImageLoad(4)}
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className={stylesHome["section-5"]}>
        <div className={stylesHome["wrap-img-section-5"]}>
          <LazyLoad height={200} offset={100} once>
            <img
              className={`${stylesHome["img-section-5"]} ${
                stylesHome["img-start"]
              } ${isLoaded[5] ? stylesHome["fade-in"] : ""}`}
              src={hospedese}
              alt="comemoração"
              onLoad={() => handleImageLoad(5)}
            />
          </LazyLoad>
        </div>
        <div className={stylesHome["wrap-all-txt"]}>
          <div className={`${stylesHome["txt-1-section-5"]} font-s`}>
            <p>hospede-se</p>
          </div>
          <div className={stylesHome["wrap-txt-section-5"]}>
            <div className="font-m">
              <p>hospede-se com a gente</p>
            </div>
            <div className="font-xs">
              <p>
                Experimente uma estadia impecável, onde cada detalhe é
                cuidadosamente planejado para proporcionar a sensação de estar
                em casa, mas com todo o conforto e exclusividade que você
                merece. Faça sua reserva agora e prepare-se para uma experiência
                verdadeiramente inesquecível!
              </p>
            </div>
            <div className={stylesHome["list-section-5"]}>
              <ul>
                <li>
                  <p className="txt-forma-before font-xs">Acomodação de luxo</p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Quartos espaçosos
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Café da manhã, janta e almoço
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Wifi e TV
                  </p>
                </li>
                <li>
                  <p className="txt-forma-before font-xs">
                    Estacionamento
                  </p>
                </li>
              </ul>
            </div>
            <div className={`${stylesHome["txt-4-section-5"]} font-s`}>
              <p>verificar disponibilidade</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home;
