import React, { useState } from "react";
import stylesRestaurant from "./restaurant.module.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import restaurant1 from "../../Assets/photos/foto-1-restaurante.jpg";
import restaurant2 from "../../Assets/photos/foto-2-restaurante.jpg";

function Restaurant() {
  return (
    <>
      <Header />

      <figure className={stylesRestaurant["wrap-figure"]}>
        <div className={stylesRestaurant["wrap-txt-figure"]}>
          <p>Restaurante</p>
        </div>
      </figure>

      <section className={stylesRestaurant["section-1"]}>
        <div className={stylesRestaurant["wrap-txt-section-1"]}>
          <p className="font-g">
            Sabores que Encantam no Planalto Palace Hotel
          </p>
          <p className="font-sm">
            No coração do Planalto Palace Hotel, nosso restaurante oferece uma
            experiência gastronômica única, com pratos que combinam o melhor da
            culinária local e internacional. Seja para um café da manhã
            relaxante, um almoço leve ou um jantar sofisticado, cada refeição é
            preparada com ingredientes frescos e selecionados, garantindo sabor
            e qualidade em cada mordida.
          </p>
        </div>
      </section>

      <section className={stylesRestaurant["section-2"]}>
        <div className={stylesRestaurant["wrap-content-section-2"]}>
          <div className={stylesRestaurant["wrap-txt-section-2"]}>
            <p className="font-m">RESTAURANTE</p>
            <p className="font-sm">
              Descubra uma experiência gastronômica única no Planalto Palace Hotel.
              Nosso restaurante combina sabores autênticos e um ambiente
              acolhedor, ideal para refeições descontraídas ou jantares
              especiais. Nossa equipe de chefs está pronta para surpreender você
              com pratos deliciosos que agradam a todos os paladares.
            </p>
            <p className="font-sm">
              Café da manhã: 6h30 até 10h30 (Segunda à Sexta), 7h até 11h
              (Sábados e Domingos) Almoço: 12h até 16h (Segunda à sexta)
              Feijoada: 13h até 16h (aos Sábados) Brunch: 13h até 16h (aos
              Domingos) Jantar: 18h até 00h (cozinha fecha às 23h todos os dias)
            </p>
            <p className="font-s txt-end line-button-right">
              saiba mais
            </p>
          </div>

          <div className={stylesRestaurant["wrap-bg-section-2"]}>
            <span className={stylesRestaurant["bg-section-2"]}></span>
          </div>

          <div className={stylesRestaurant["wrap-photo"]}>
            <img
              className={stylesRestaurant["img-section-2"]}
              src={restaurant1}
              alt=""
            />
          </div>
          
        </div>
      </section>

      <section className={stylesRestaurant["section-3"]}>
        <div className={stylesRestaurant["wrap-content-section-3"]}>
          <div className={stylesRestaurant["wrap-photo"]}>
            <img
              className={stylesRestaurant["img-section-3"]}
              src={restaurant2}
              alt=""
            />
          </div>
          <div className={stylesRestaurant["wrap-txt-section-3"]}>
            <p className="font-m">algumas opções</p>
            <div>
            <p className="font-sm">
              <strong>Pratos principais:</strong> 
            </p>
            <p className="txt-forma font-sm">
            Filé mignon
            </p>
            <p className="txt-forma font-sm">
            Filé de peito de frango
            </p>
            <p className="txt-forma font-sm">
            Lasanha de carne ou frango
            </p>
            <p className="txt-forma font-sm">
            Strogonoff de carne ou frango
            </p>
            <p className="txt-forma font-sm">
            Caldo de carne ou carne
            </p>
            </div>
            <div className={stylesRestaurant["wrap-options-section-3"]}>
            <div>
            <p className="font-sm">
              <strong>Lanches deliciosos:</strong> 
            </p>
            <p className="txt-forma font-sm">
            Omelete recheado 
            </p>
            <p className="txt-forma font-sm">
            Misto quente
            </p>
            <p className="txt-forma font-sm">
            Panquecas
            </p>
            <p className="txt-forma font-sm">
            Tapioca
            </p>
            </div>
            <div>
            <p className="font-sm">
              <strong>Bebidas:</strong> 
            </p>
            <p className="txt-forma font-sm">
            Refrigerantes
            </p>
            <p className="txt-forma font-sm">
            Sucos naturais
            </p>
            <p className="txt-forma font-sm">
            Cervejas
            </p>
            </div>
          </div>
          <p className="font-s txt-end line-button-right">
              saiba mais
            </p>
          </div>
          <div className={stylesRestaurant["wrap-bg-section-3"]}>
            <span className={stylesRestaurant["bg-section-3"]}></span>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Restaurant;
